import jquery from 'jquery'
import "@rails/request.js"
import * as bootstrap from 'bootstrap'
import { createApp } from 'vue/dist/vue.esm-bundler';
import { createI18n } from 'vue-i18n'
import Rails from '@rails/ujs';
import ActionCableVue from "actioncable-vue";

import TimetableShift from '../components/timetable-shift.vue'
import TimetablePositions from '../components/timetable-positions.vue'
import TimetableVolunteers from '../components/timetable-volunteers.vue'
import TimetableAttendance from '../components/timetable-attendance.vue'

window.jQuery = jquery
window.$ = jquery
window.bootstrap = bootstrap


// Extend bootstrap's tooltip sanitizer to correctly display tags
// See: https://getbootstrap.com/docs/5.2/getting-started/javascript/#sanitizer
var tooltipSanitizerWhitelist = bootstrap.Tooltip.Default.allowList
tooltipSanitizerWhitelist.a.push('data-remote', 'data-method')

const app = createApp().use(ActionCableVue, {})

/* vue-i18n (https://github.com/intlify/vue-i18n-next) */
const messages = {
  en: {
    timetable: {
      positions: {
        headers: {
          noGroup: "(Groupless)",
          position: 'Name'
        }
      },
      shifts: {
        helper: {
          position_filled: "Position besetzt",
          position_unfilled: "Position nicht besetzt"
        }
      },
      volunteers: {
        headers: {
          noGroup: "(Groupless)",
          volunteer: 'Name'
        }
      }
    }
  },
  de: {
    timetable: {
      positions: {
        headers: {
          noGroup: "(Gruppenlos)",
          position: 'Name'
        }
      },
      shifts: {
        helper: {
          position_filled: "Position filled",
          position_unfilled: "Position unfilled"
        }
      },
      volunteers: {
        headers: {
          noGroup: "(Gruppenlos)",
          volunteer: 'Name'
        }
      }
    }
  }
}
const i18n = createI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages,
})
app.use(i18n)

app.component('VueTimetableShift', TimetableShift)
app.component('VueTimetablePositions', TimetablePositions)
app.component('VueTimetableVolunteers', TimetableVolunteers)
app.component('VueTimetableAttendance', TimetableAttendance)
app.mount('#app');

Rails.start();
