<script>
export default {
  props: {
    event: Object,
  },
  mounted() {
    new bootstrap.Tooltip(this.$refs.tooltip)
  },
  data() {
    return {
      isModerator() {
        return this.event.extendedProps.isEditable
      },
      isEvent() {
        return this.event.display != "inverse-background" && this.event.display != "background"
      },
      statusStyle: {
        padding: "0.2rem",
        "margin-bottom": "-4px",
        top: "-2px",
        left: "-1px",
        position: "relative",
        color: this.event.extendedProps.houseColor,
        backgroundColor: this.event.extendedProps.houseBackgroundColor

      },
      statusClass: [
        "me-1",
        "border-end",
        "border-light",
        "d-inline-block",
      ]
    }
  }
}
</script>
<template>
  <div class="d-flex align-items-stretch">
    <a v-if="this.isEvent() && this.isModerator()" :style="statusStyle" :class="statusClass" :href="event.extendedProps.toggleUrl" rel="nofollow" data-remote="true" data-method="patch">
      <span v-if="event.extendedProps.movedInto" class="bi bi-geo-alt-fill" :title="this.$i18n.t('timetable.shifts.helper.position_filled')"></span>
      <span v-else class="bi bi-geo-alt" :title="this.$i18n.t('timetable.shifts.helper.position_unfilled')"></span>
    </a>
    <span v-else-if="this.isEvent()" :style="statusStyle" :class="statusClass">
      <span v-if="event.extendedProps.movedInto" class="bi bi-geo-alt-fill" :title="this.$i18n.t('timetable.shifts.helper.position_filled')"></span>
      <span v-else class="bi bi-geo-alt" :title="this.$i18n.t('timetable.shifts.helper.position_unfilled')"></span>
    </span>
    <div>
      <span>
        <span class="me-1">{{ event.title }}</span>
        <span v-show="event.extendedProps.comment" ref="tooltip" class="bi bi-chat-left-dots me-1" data-toggle="tooltip" data-placement="top" :title="event.extendedProps.comment"></span>
      </span>
      <a v-if="this.isModerator()" style="color: inherit" class="me-1" :href="event.extendedProps.duplicateUrl" rel="nofollow" data-remote="true" data-method="post">
        <span class="bi bi-plus-circle"></span>
      </a>
      <a v-if="this.isModerator()" style="color: inherit" class="me-1" :href="event.url" rel="nofollow" data-remote="true" data-method="delete">
        <span class="bi bi-trash"></span>
      </a>
    </div>
  </div>
</template>
